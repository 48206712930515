body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parallax {
  height: 100%;
  background-color: aliceblue;
}

br {
  display: block;
  margin-bottom: 10px; /* Add some margin below the <br> element */
  content: ''; /* Add an empty content property to prevent issues in some browsers */
}

/* .image {
  position: relative;
  height: 100vh;
}

.description {
  display: block;
  background-color: black;
  height: 100vh;
}

.parallax-grid {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 0px;
  height: 75vh;

  flex-grow: 1;
}

.parallax-grid-description {
  background-color: gray;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10%;

}

.title {
  font-size: clamp(20px, 5vw, 80px); 
}

.subtitle {
  font-size: clamp(15px, 3vw, 45px); 
}

.parallax-grid-image {
  background-color: red;
  margin-right: 10%;
}
*/

